<template>
    <div>
        <div class="admin">
            <div class="" v-show="!loading">
                <div class="md-tabs md-tabs-bulk">
                    <div class="">
                        <md-card>
                            <div class="md-layout">
                                <div class="md-layout-item fix-height-46 md-size-50" v-if="!mobile">
                                    <md-card-actions class="actions bulk-edit-buttons" md-alignment="left">
                                        <md-button
                                                v-if="showGeneral"
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.general===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.general=true"
                                        >General<div class="blue-bar"></div></md-button>
                                        <md-button
                                                v-if="activeSubscriptions.length"
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.admissions===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.admissions = true"
                                        >Admissions</md-button>
                                        <md-button
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.contact===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.contact = true"
                                        >Contact</md-button>
                                        <md-button
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.summary===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.summary = true"
                                        >Summary</md-button>
                                        <md-button
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.overview===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.overview = true"
                                        >Overview</md-button>
                                        <md-button
                                                class="md-raised md-primary"
                                                :class="{ selected: modals.media===true }"
                                                :disabled="selectedFacilities.length < 2"
                                                @click="modals.media = true"
                                        >Media</md-button>
                                        </md-card-actions>
                                </div>

                                <div class="md-layout-item fix-height-46 md-size-30">
                                    <md-field md-clearable class="md-toolbar-section-end table-search-field">
                                        <md-input placeholder="Search" v-model="search" @input="searchOnTable(true)" class="table-search-input" />
                                    </md-field>
                                </div>
                            </div>
                        </md-card>

                        <div id="facilities-table" class="table-wrapper">
                            <md-table
                                    v-model="facilities"
                                    :md-sort.sync="currentSort"
                                    :md-sort-order.sync="currentSortOrder"
                                    :md-sort-fn="customSort"
                                    md-card
                                    v-if="facilities && !mobile"
                                    @md-selected="facilitySelect"
                            >
                                    <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple" md-auto-select >
                                    <md-table-cell class="facility-name" md-sort-by="name" md-label="Listing">
                                        <router-link
                                                :to="{name: 'general.index', params: { facilityId: item.facilityId}}"
                                        >{{ item.name }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell md-label="City" md-sort-by="city" v-if="!mobile">{{ item.city }}</md-table-cell>
                                    <md-table-cell md-label="State" v-if="!mobile" md-sort-by="state">{{ item.state }}</md-table-cell>
                                    <md-table-cell md-label="Zip"  md-sort-by="zip">{{ item.zip }}</md-table-cell>
                                    <md-table-cell md-label="Updated" v-if="!mobile" md-sort-by="updatedAt">{{facilityUpdatedDate(item.facilityId)}}</md-table-cell>
                                    <md-table-cell
                                            md-label="Dashboard"
                                            md-sort-by="premiumDashboard"
                                            v-if="!mobile"
                                    >
                                        <a @click.stop="toggleFacilityOnDashboard(item)">{{item.premiumDashboard ? 'Yes' : 'No'}}</a>
                                    </md-table-cell>
                                    <md-table-cell md-label="View"><a class="url" target="_blank"  :href="item.url">View</a></md-table-cell>
<!--                                            <md-table-cell md-label="Content score" md-sort-by="gender">?</md-table-cell>-->
                                   <!--- <md-table-cell md-label="Subscription" md-sort-by="title">{{ isFacilitySubscription(item) }}</md-table-cell>
                                    <md-table-cell md-label="Premium Calls" md-sort-by="title">{{ isPremiumCalls(item) }}</md-table-cell>
                                    <md-table-cell md-label="Premium Clicks" md-sort-by="title">{{ isPremiumClicks(item) }}</md-table-cell>-->
                                </md-table-row>
                            </md-table>

                             <md-table
                                    v-model="facilities"
                                    :md-sort.sync="currentSort"
                                    :md-sort-order.sync="currentSortOrder"
                                    :md-sort-fn="customSort"
                                    md-card
                                    v-if="facilities && mobile"
                                    @md-selected="facilitySelect"
                                    class="md-table-mobile-padding"
                            >
                                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell class="facility-name" md-sort-by="name" md-label="Listing">
                                        <router-link
                                                :to="{name: 'general.index', params: { facilityId: item.facilityId}}"
                                        >{{ item.name }}
                                        </router-link>
                                    </md-table-cell>
                                    <md-table-cell md-label="City" md-sort-by="city" v-if="!mobile">{{ item.city }}</md-table-cell>
                                    <md-table-cell md-label="State" v-if="!mobile" md-sort-by="state">{{ item.state }}</md-table-cell>
                                    <md-table-cell md-label="Zip" md-sort-by="zip">{{ item.zip }}</md-table-cell>
                                    <md-table-cell md-label="Updated" v-if="!mobile" md-sort-by="updatedAt">{{facilityUpdatedDate(item.facilityId)}}</md-table-cell>
                                    <md-table-cell
                                            md-label="Dashboard"
                                            md-sort-by="premiumDashboard"
                                            v-if="!mobile"
                                    >
                                        <a @click.stop="toggleFacilityOnDashboard(item)">{{item.premiumDashboard ? 'Yes' : 'No'}}</a>
                                    </md-table-cell>
                                    <md-table-cell md-label="View"><a class="url" target="_blank"  :href="item.url">View</a></md-table-cell>
<!--                                            <md-table-cell md-label="Content score" md-sort-by="gender">?</md-table-cell>-->
                                   <!--- <md-table-cell md-label="Subscription" md-sort-by="title">{{ isFacilitySubscription(item) }}</md-table-cell>
                                    <md-table-cell md-label="Premium Calls" md-sort-by="title">{{ isPremiumCalls(item) }}</md-table-cell>
                                    <md-table-cell md-label="Premium Clicks" md-sort-by="title">{{ isPremiumClicks(item) }}</md-table-cell>-->
                                </md-table-row>
                            </md-table>

                            <div class="md-layout bulk-nav" v-if="formattedFacilities.length > this.numShowFacilities">
                                <div class="md-layout-item table-nav">
                                    <md-button
                                            class="md-raised md-primary"
                                            :disabled="currentPage === 1"
                                            @click="previousPage"
                                    >Previous</md-button>
                                    <md-button
                                            class="md-raised md-primary"
                                            :disabled="nextDisabled"
                                            @click="nextPage"
                                    >Next</md-button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            
            <md-progress-spinner md-mode="indeterminate" class="spinner" v-if="loading"></md-progress-spinner>
        </div>

        <!--  GENERAL FORM MODAL -->
        <md-dialog id="general-modal" class="bulk-edit-modal" :md-active.sync="modals.general">
            <md-dialog-title class="bulkTitle">General bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <md-dialog-content>
                <div class="admin bulk-admin">
                    <general-form
                            :bulk="true"
                            save-button-text="Save"
                            @submit="bulkUpdateGeneral($event)"
                            @cancel="modals.general=false"
                    ></general-form>
                </div>
            </md-dialog-content>
        </md-dialog>

        <!--  ADMISSIONS FORM MODAL -->
        <md-dialog id="admissions-modal" class="bulk-edit-modal" :md-active.sync="modals.admissions">
            <md-dialog-title class="bulkTitle">Admissions bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <md-dialog-content>
                <div class="admin bulk-admin">
                    <admissions-agent-form
                            :bulk="true"
                            @submit="bulkUpdateAdmissionsAgent($event)"
                            @cancel="modals.admissions=false"
                    ></admissions-agent-form>
                </div>
            </md-dialog-content>
        </md-dialog>

        <!--  CONTACT FORM MODAL -->
        <md-dialog
                id="contact-modal"
                class="bulk-edit-modal"
                :class="{'contact-top-1': (contactShowPhone || contactShowUrl) && !(contactShowPhone && contactShowUrl), 'contact-top-2': contactShowPhone && contactShowUrl}"
                :md-active.sync="modals.contact"
        >
            <md-dialog-title class="bulkTitle">Contact bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <md-dialog-content>
                <div class="admin bulk-admin">
                    <contact-form
                            :bulk="true"
                            :bulk-facilities="selectedFacilities"
                            @submit="bulkUpdateContactInfo($event)"
                            @cancel="modals.contact=false"
                            @showReceivingUrl="contactShowUrl = $event"
                            @showReceivingPhone="contactShowPhone = $event"
                    ></contact-form>
                </div>
            </md-dialog-content>

        </md-dialog>

        <!--  SUMMARY FORM MODAL -->
        <md-dialog id="summary-modal" class="bulk-edit-modal bulk-admin" :md-active.sync="modals.summary">
            <md-dialog-title class="bulkTitle">Summary bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <md-dialog-content>
                <div class="admin">
                        <summary-form
                                :bulk="true"
                                :selectedFacilities="selectedFacilities"
                                @submit="bulkUpdateSummary($event)"
                                @close="modals.summary = false"
                                @cancel="modals.summary = false"
                        ></summary-form>
                </div>
            </md-dialog-content>
        </md-dialog>

        <!--  OVERVIEW FORM MODAL -->
        <md-dialog id="overview-modal" class="bulk-edit-modal bulk-admin" :md-active.sync="modals.overview">
            <md-dialog-title class="bulkTitle">Overview bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <md-dialog-content>
                <div class="admin">
                    <overview-form
                            :bulk="true"
                            :selectedFacilities="selectedFacilities"
                            @submit="bulkUpdateOverview($event)"
                            @close="modals.overview = false"
                            @cancel="modals.overview = false"
                    ></overview-form>
                </div>
            </md-dialog-content>
        </md-dialog>

        <!--  MEDIA FORM MODAL -->
        <md-dialog
            id="media-modal"
            class="bulk-edit-modal bulk-admin bulk-media"
            :class="{ 'bulk-media-with-profile-image': mediaProfileImage }"
            :md-active.sync="modals.media"
        >
            <md-dialog-title class="bulkTitle">Media bulk edit <span class="bulk-listing-number">({{selectedFacilities.length}} listings selected)</span></md-dialog-title>
            <div class="admin">
                <media-form
                        ref="bulkMedia"
                        :bulk="true"
                        @submit="bulkUpdateMedia($event)"
                        @cancel="modals.media = false"
                        @bulkSaveImage="bulkSaveProfileImage($event)"
                ></media-form>
            </div>

        </md-dialog>


        <md-snackbar
                md-position="center"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script lang="javascript">
    import { format, parse } from 'date-fns'
    import {mapGetters, mapActions, mapMutations} from 'vuex'
    import GeneralForm from "../Forms/GeneralForm";
    import AdmissionsAgentForm from "../Forms/AdmissionsAgentForm";
    import ContactForm from "../Forms/ContactForm";
    import SummaryForm from "../Forms/SummaryForm";
    import OverviewForm from "../Forms/OverviewForm";
    // import Description from "../Description"
    import MediaForm from "../Forms/MediaForm";

    export default {
        name: 'BulkEdit',
        components: {
            GeneralForm,
            AdmissionsAgentForm,
            ContactForm,
            SummaryForm,
            OverviewForm,
            MediaForm,
            // Description
        },
        data() {
            return {
                modalLoading: false,
                showSnackbar: false,
                snackMsg: '',
                loading: false,
                formChanged: false,
                selectedFacilities: [],
                facilityUpdatedDates: [],
                modals: {
                    general: false,
                    admissions: false,
                    contact: false,
                    summary: false,
                    overview: false,
                    media: false
                },
                tabId: 'summary',
                facilities: null,
                search: null,
                currentSort: 'name',
                currentSortOrder: 'asc',
                numShowFacilities: 15, // pagination
                currentPage: 1, //pagination,
                contactShowPhone: false,
                contactShowUrl: false,
                mobile: false,
                mediaProfileImage: false
            }
        },

        computed: {
            ...mapGetters({
                originalFacilities: 'facilities',
                subscriptions: 'subscriptions',
                callProduct: 'callProduct',
                clickProduct: 'clickProduct',
                campaigns: 'campaigns',
                activeSubscriptions: 'activeSubscriptions',
                getFacilityType: 'getFacilityType',
            }),

            nextDisabled() {
                if(this.search) {
                    if(this.currentPage === Math.ceil(this.searchOnTable(false).length / this.numShowFacilities)){
                        return true;
                    }
                }
                else if(this.currentPage === Math.ceil(this.formattedFacilities.length / this.numShowFacilities)){
                    return true;
                }
                return false;
            },

            formattedFacilities() {
                return this.originalFacilities.map(facility => {
                    return {
                        id: facility.id,
                        facilityId: facility.attributes.facilityId,
                        name: facility.attributes.name,
                        city: facility.attributes.city,
                        state: facility.attributes.state,
                        zip: facility.attributes.zip,
                        premiumDashboard: facility.attributes.premiumDashboard,
                        updatedAt: this.facilityUpdatedDate(facility.attributes.facilityId),
                        url: this.createMySpecialUrl(facility.attributes.facilityId)
                    }
                })
            },

            // DON'T SHOW GENERAL TAB FOR NON ADDICTION LISTINGS
            showGeneral() {
                if(this.selectedFacilities.length){
                    for(const facilityId of this.selectedFacilities){
                        const facilityType = this.getFacilityType(facilityId);
                        if(!facilityType) return false
                        return facilityType.slug === "addiction_rehab"
                    }
                } else if(this.facilities && this.facilities.length) {
                    for(const facility of this.facilities){
                        const facilityType = this.getFacilityType(facility.facilityId)
                        if(!facilityType) return false
                        return facilityType.slug === "addiction_rehab"
                    }
                }

                return true;
            }
        },

        watch: {
            'modals.media'(newVal, oldVal){
                this.mediaProfileImage = false;
            }
        },

        created(){
             if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        async mounted() {
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            this.loading = true;
            await this.loadData()
            this.loading = false;
        },

        methods: {
            ...mapActions([
                'fetchFacilityDataChildNames',
                'fetchFacilitiesLastUpdatedAt',
                'bulkUpdateGeneralInfo',
                'submitAdmissionsAgent',
                'updateContactPageInfo',
                'updateFacilitySummary',
                'updateFacilityOverview',
                'updateFacilityMediaPage',
                'updateFacility',
                'saveFacilityImage'
            ]),
            ...mapMutations([
                'changeFacilityPremiumDashboard'
            ]),

            async loadData() {
                this.facilityUpdatedDates = await this.fetchFacilitiesLastUpdatedAt();
                await this.fetchFacilityDataChildNames();
                if(this.originalFacilities){
                    this.facilities = this.customSort();
                }
            },

            facilitySelect(facilities) {
                this.selectedFacilities = facilities.map(facility => facility.facilityId)
            },
            createMySpecialUrl(facilityId){
            // console.log(process.env.NODE_ENV)
                if(process.env.NODE_ENV === 'production'){
                    if(window.location.origin === "https://connect-staging.rehab.com"){
                        return "https://staging.rehab.com/"+facilityId
                    }else if(window.location.origin === "https://connect-dev.rehab.com"){
                        return "https://dev.rehab.com/"+facilityId
                    }else if(window.location.origin === "https://connect-demo.rehab.com"){
                        return "https://demo.rehab.com/"+facilityId
                    }
                    return "https://www.rehab.com/"+facilityId
                }
                else if(process.env.NODE_ENV === 'local'){
                    return "http://local.rehab.com/"+facilityId
                }
            },

            async toggleFacilityOnDashboard(facility) {
                try {
                    // MAKE SURE THERE ARE ONLY 10 OR LESS PREMIUM DASHBOARD FACILITIES
                    if(!facility.premiumDashboard){
                        const premiumDashboardFacilities = this.originalFacilities.filter(facility => facility.attributes.premiumDashboard);
                        console.log('PREMIUM DASHBOARD FACILITIES', premiumDashboardFacilities);
                        if(premiumDashboardFacilities.length >= 10){
                            this.showSnackbar = true;
                            this.snackMsg = "Only 10 facilities are allowed on the Premium Dashboard. Please hide a facility before adding more."
                            return;
                        }
                    }
                    // UPDATE THE FACILITY ON THE BACKEND
                    const data = {premiumDashboard: !facility.premiumDashboard }
                    await this.updateFacility({
                        facilityId: facility.facilityId,
                        data
                    });
                    // UPDATE FRONTEND FACILTIY OBJECT
                    this.changeFacilityPremiumDashboard(facility.facilityId)
                    facility.premiumDashboard = !facility.premiumDashboard
                }catch(e){
                    console.error(e);
                    this.snackMsg = "Error changing facility dashboard status."
                    this.showSnackbar = true;
                }
            },

            searchOnTable(fromTable = true) {
                const facilities = this.formattedFacilities.filter(facility => {
                    if(
                        facility.name.toLowerCase().includes(this.search.toLowerCase()) ||
                        facility.city.toLowerCase().includes(this.search.toLowerCase()) ||
                        facility.state.toLowerCase().includes(this.search.toLowerCase()) ||
                        facility.zip.toLowerCase().includes(this.search.toLowerCase())
                    ){
                        return facility;
                    }

                    return false
                });
                if(fromTable){
                    this.facilities = facilities.slice(0, this.numShowFacilities)
                }else {
                    return facilities
                }
            },

            customSort(value, resetPage = true){
                let facilities = JSON.parse(JSON.stringify(this.formattedFacilities))
                if(this.search){
                    facilities = this.searchOnTable(false)
                }
                const sorted = facilities.sort(this.sortArray(this.currentSort, this.currentSortOrder))

                if(resetPage) this.currentPage = 1;

                let startRange = (this.currentPage - 1) * this.numShowFacilities;
                let endRange = this.numShowFacilities * (this.currentPage);

                this.facilities = sorted.slice(startRange, endRange);

                return this.facilities;
            },

            sortArray(key, order = 'asc') {
                return function innerSort(a, b) {
                    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                        // property doesn't exist on either object
                        return 0;
                    }

                    let varA = (typeof a[key] === 'string')
                        ? a[key].toUpperCase() : a[key];
                    let varB = (typeof b[key] === 'string')
                        ? b[key].toUpperCase() : b[key];

                    if(key === 'updatedAt') {
                        varA = parse(a[key]);
                        varB = parse(b[key]);
                    }

                    let comparison = 0;
                    if (varA > varB) {
                        comparison = 1;
                    } else if (varA < varB) {
                        comparison = -1;
                    }
                    return (
                        (order === 'desc') ? (comparison * -1) : comparison
                    );
                };
            },

            nextPage(){
                this.currentPage += 1;
                this.customSort(null, false);
            },

            previousPage(){
                this.currentPage -= 1;
                this.customSort(null, false);
            },

            facilityUpdatedDate(facilityId) {
                const date = this.facilityUpdatedDates[facilityId];
                if(date) return format(parse(date.date), 'MM/DD/YYYY')
                return ""
            },

            async bulkUpdateGeneral({formData, submitFields}){
                try {
                    this.modals.general = false;
                    this.loading = true;
                    await this.bulkUpdateGeneralInfo({facilityIds: this.selectedFacilities, formData, submitFields})
                    this.loading = false;

                    this.snackMsg = "General information updated for the selected facilities"
                    this.showSnackbar = true;
                } catch(e) {
                    console.error(e);
                    this.modals.general = true;
                    this.loading = false;
                    this.snackMsg = "Error saving general information."
                    this.showSnackbar = true;
                }


            },

            async bulkUpdateAdmissionsAgent(formData){
                try {
                    this.modals.admissions = false;
                    this.loading = true;

                    for(const facilityId of this.selectedFacilities){
                        await this.submitAdmissionsAgent({
                            facilityId,
                            formData
                        })
                    }
                    this.loading = false;

                    this.snackMsg = "Admissions Agent updated for the selected facilities"
                    this.showSnackbar = true;
                }catch(e){
                    console.error(e);
                    this.modals.admissions = true;
                    this.loading = false;
                    this.snackMsg = "Error saving admissions agent information."
                    this.showSnackbar = true;
                }

            },

            async bulkUpdateContactInfo({information, submitFields}) {
                try {
                    this.modals.contact = false;
                    this.loading = true;
                    // console.log('BULK UPDATE CONTACT INFO', information, submitFields);

                    const promises = []
                    for(const facilityId of this.selectedFacilities){
                        promises.push(this.updateContactPageInfo({
                            facilityId,
                            formData: information,
                            submitFields,
                            bulk: true
                        }))
                    }
                    await Promise.all(promises);
                    this.loading = false;

                    this.snackMsg = "Contact information updated for the selected facilities"
                    this.showSnackbar = true;
                }catch(e){
                    console.error(e);
                    this.modals.contact = true;
                    this.loading = false;
                    this.snackMsg = "Error saving contact information."
                    this.showSnackbar = true;
                }

            },

            tabChanged(id){
                // console.log('tab changed ', id);
                this.tabId = id;
                if(id === 'summary' && this.descriptionPendingUpdates){
                    this.showPendingReviewSnackbar()
                }
                else if(id === 'overview' && this.overviewPendingUpdates){
                    this.showPendingReviewSnackbar()
                }
            },

            async bulkUpdateSummary(formData) {
                try {
                    this.modals.summary = false;
                    this.loading = true;

                    const promises = []
                    for(const facilityId of this.selectedFacilities){
                        promises.push(this.updateFacilitySummary({
                            facilityId,
                            facilityDescription: formData.facilityDescription
                        }))
                    }

                    await Promise.all(promises);

                    this.loading = false;
                    this.snackMsg = "Summary updated for the selected facilities"
                }catch(e){
                    console.error(e);
                    this.modals.summary = true;
                    this.loading = false;
                    this.snackMsg = "Error saving summary."
                    this.showSnackbar = true;
                }

            },

            async bulkUpdateOverview(formData){
                try {
                    this.modals.overview = false;
                    this.loading = true;

                    const promises = []
                    for(const facilityId of this.selectedFacilities){
                        promises.push(this.updateFacilityOverview({
                            facilityId,
                            formData
                        }))
                    }

                    await Promise.all(promises);

                    this.loading = false;
                    this.snackMsg = "Overview updated for the selected facilities"
                }catch(e){
                    console.error(e);
                    this.modals.overview = true;
                    this.loading = false;
                    this.snackMsg = "Error saving overview."
                    this.showSnackbar = true;
                }

            },

            async bulkUpdateMedia({submitObject, submitFields}){
                try {
                    this.modals.media = false;
                    this.loading = true;

                    const promises = [];
                    for(const facilityId of this.selectedFacilities){
                        promises.push(this.updateFacilityMediaPage({
                            facilityId,
                            submitObject,
                            submitFields
                        }))
                    }

                    await Promise.all(promises);

                    this.loading = false;
                    this.snackMsg = "Media updated for the selected facilities"
                    this.showSnackbar = true;
                }catch(e){
                    console.error(e);
                    this.modals.media = true;
                    this.loading = false;
                    this.snackMsg = "Error saving media information."
                    this.showSnackbar = true;
                }
            },

            async bulkSaveProfileImage(formData){
              try {
                this.loading = true;
                this.mediaProfileImage = true;
                const promises = [];
                for(const facilityId of this.selectedFacilities){
                  // console.log('UPDATING IMAGE FOR', facilityId, formData)
                  promises.push(this.saveFacilityImage({
                    formData,
                    facilityId
                  }))
                }
                await Promise.all(promises)
                this.loading = false;
                this.$refs.bulkMedia.stopBulkLoader()
                this.snackMsg = "Profile image updated for the selected facilities"
                this.showSnackbar = true;
              }catch(e){
                this.loading = false;
                console.error(e);
              }
            }
        },
    }

</script>

<style lang="scss" scoped>
    h3 {
        margin-left: 7px;
    }

    .actions{
        width: 640px;
        padding-bottom: 20px;
        padding-top: 0px;
    }

    hr {
        margin:0 0 0 0px;
        /*border-color: #eee*/
        border: 0;
        height: 0;
        border-top: 1px solid #eee;
        border-bottom: none;
    }



    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }

    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }

    .facility-name {
        padding-left: 24px;
    }

    .md-tab {
        padding: 0x;
    }

    .md-card {
        border-bottom: none;
        box-shadow: none !important;
    }

    #general-modal {
        top: 435px;
        max-height: 98%;
        width: 640px;
    }

    #admissions-modal {
        top: 385px;
        max-height: 98%;
        width: 640px;
    }

    #contact-modal {
        top: 213px;
        width: 640px;
    }

    .contact-top-1 { // Either receiving number or receiving url are viewable, not both
        top: 249px !important;
    }

    .contact-top-2 { // Both receiving number and receiving url are viewable on form.
        top: 285px !important;
    }

    #summary-modal{
        top: 231px;
        width: 640px;
    }

    #overview-modal{
        top: 231px;
        width: 640px;
    }

    #media-modal {
        top: 215px;
        width: 640px;
    }
    .bulk-media-with-profile-image {
      top: 287px !important;
    }

    #description-modal {
        top: 280px;
        width: 800px;

        #description-tabs{
            box-shadow: none;
            width: 720px;
        }

        .md-card{
            margin-left: 0px;
            width: 100%;
            margin-right: 0px;
        }

        .md-app-content {
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 0px;
            padding-top: 0px;
            padding: 0px;
        }
        .md-tab{
            padding: 0;
            width: 400px;
        }



        .actions {
            width: 599px;
            padding-top: 20px;
            padding-bottom: 20px;
            .md-primary{
                margin-left: 10px;
            }
        }
        .md-card{
            box-shadow: none;
        }
    }

    .spinner{
        top:50%;
        margin-top: -50px;
        left: 50%;
        margin-left: -40px;
        position: absolute;
    }

    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }

    .bulk-nav {
        .md-layout-item {
            flex: 1 1
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .table-search-field .md-input.table-search-input{
            padding-left: 8px;
        }
        
        .facility-name{
            padding-left: 8px!important;
        }
        .admin .facility-name{
            padding-left: 0px!important;
        }
    }

</style>

<style lang="scss">
    #facilities-table {
        .md-table-content thead tr, .md-table.md-theme-default .md-table-head {
            border-bottom-color:rgb(128, 128, 128);
            border-bottom-left-radius:0px;
            border-bottom-right-radius:0px;
            border-bottom-style:none;
            border-bottom-width:0px;
        }

        div > div > table > thead > tr {
            th:nth-child(2) > div > div {
                margin-left: 24px !important;
            }

            th:nth-child(n+4) {
                //div {
                //    text-align: right;
                //}
                .md-table-head-label {
                    padding-right: 20px;
                }
            }
            th:last-child {
                .md-table-head-label {
                    padding-right: 24px;
                }
            }
        }

        .md-table-content thead tr {
            border-bottom: none;
        }

        .alignRight{
            text-align: right;
        }

        .md-table-sortable-icon {
            top: 7px;
        }
    }
    .bulkTitle{
        padding-top: 34px;
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 10px;
        margin-left: 17px;
        .bulk-listing-number {
          font-size: 24px;
        }
    }

    .bulk-edit-buttons{
        height: 48px!important;
    }
    .md-tabs .md-card{
        border-bottom: #eee solid 1px;
        .fix-height-46{
            height: 46px;
            .md-card-actions{
                height: 44px;
                .bulk-edit-buttons{
                    height: 44px;
                }
            }
        }
    }
    #facilities-table .md-table{
        border-bottom: none;
    }

    //.bulk-admin .md-layout-item input{
    //    margin-left: 0px;
    //}

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        #facilities-table {
            .md-table-content thead tr, .md-table.md-theme-default .md-table-head {
                border-bottom-color:rgb(128, 128, 128);
                border-bottom-left-radius:0px;
                border-bottom-right-radius:0px;
                border-bottom-style:none;
                border-bottom-width:0px;
            }

            div > div > table > thead > tr {
                th:nth-child(2) > div > div {
                    margin-left: 0px !important;
                }

                th:nth-child(n+4) {
                    div {
                        text-align: right;
                    }
                    .md-table-head-label {
                        padding-right: 0px;
                    }
                }
                th:last-child {
                    .md-table-head-label {
                        padding-right: 15px;
                    }
                }
            }

            .md-table-content thead tr {
                border-bottom: none;
            }

            .alignRight{
                text-align: right;
            }

            .md-table-sortable-icon {
                top: 7px;
            }
        }
    }
    
   
</style>